import { Component, OnInit } from '@angular/core';
import { AuthService, ConfigStateService } from '@abp/ng.core';
import {
	NgbOffcanvas,
	OffcanvasDismissReasons,
	NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ProfilePictureService, eProfilePictureType } from '@volo/abp.commercial.ng.ui/config';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LayoutService } from '../../services/layout.service';
import { switchMap, catchError, empty } from 'rxjs';


@Component({
	selector: 'app-navbar',
	templateUrl: 'navbar.component.html',
	providers: [LayoutService],
})
export class NavBarComponent implements OnInit {

	public profilePic: string;
	public profileType: eProfilePictureType;

	constructor(
		private _authServ: AuthService,
		private _config: ConfigStateService,
		private offcanvasService: NgbOffcanvas,
		public service: LayoutService,
		private _profilePicture: ProfilePictureService
	) {}

	userName = '';
	userRole = '';
	initials = '';
	isLoggedIn = false;

	ngOnInit() {
		this._config.getOne$('currentUser').subscribe(currentUser => {
			if (currentUser['isAuthenticated']) {
				this.isLoggedIn = true;
				this.userRole = currentUser['roles'][0];

				if (!currentUser['name'] || !currentUser['surName']) {
					this.userName = currentUser['userName'];
					this.initials = this.userName.charAt(0);
				} else {
					this.userName = currentUser['name'] + ' ' + currentUser['surName'];
					this.initials =
						currentUser['name'].charAt(0) + currentUser['surName'].charAt(0);
				}
				this.initials = this.initials.toLocaleUpperCase();
			}
		});

		this._config.getOne$('currentUser')
			.pipe(
				switchMap((currentUser) => {
					if (currentUser['id']) {
						return this._profilePicture.getProfilePicture(currentUser['id'])
					}
					else {
						return empty()
					}
				})
			)
			.subscribe(result => {
				this.profileType = result.type;
				this.profilePic = `data:image/png;base64,${result.fileContent}`;
			})
	}

	logout() {
		this._config.getOne$('currentUser').subscribe(currentUser => {
			if (currentUser['isAuthenticated']) {
				this._authServ.logout().subscribe(() => {
					this.isLoggedIn = false;
				});
			}
		});
	}
	toggleDashboard() {
		let el = document.getElementById('offcanvasSideBar').classList;
		el.contains('show') ? el.remove('show') : el.add('show');
	}

	open(content) {
		this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' });
	}
}
