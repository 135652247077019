import { ABP, RoutesService, TreeNode, ConfigStateService } from '@abp/ng.core';
import {
	Component,
	ElementRef,
	Input,
	QueryList,
	Renderer2,
	TrackByFunction,
	ViewChildren,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'abp-routes',
	templateUrl: 'routes.component.html',
})
export class RoutesComponent implements OnInit {
	dashboardIcons = [
		'isax-teacher',
		'isax-people',
		'isax-personalcard',
		'isax-clipboard-text',
		'isax-clipboard-tick',
		'isax-profile-2user',
		'isax-note-2',
		'isax-book',
		'isax-video',
		'isax-message-edit',
		'isax-house',
		'isax-book-1',
		'isax-user',
		'isax-dollar-square',
		'isax-archive-book',
		'isax-edit-2',
		'isax-people',
		'isax-key',
		'isax-login',
		'isax-receipt',
		'isax-support',
		'isax-document-1',
	];

	getIconIndex(name: string) {
		switch (name) {
			case '::Menu:Students':
				return 0;
			case '::Menu:Partners':
				return 1;
			case '::Menu:Enrollments':
				return 2;
			case 'Reports':
				return 3;
			case '::Menu:Approvals':
				return 4;
			case '::Menu:Leads':
				return 5;
			case '::Menu:Courses':
				return 6;
			case '::Menu:Programs':
				return 7;
			case '::Menu:MediaFiles':
				return 8;
			case 'Blogs':
				return 9;
			case '::Menu:Home':
				return 10;
			case 'My Courses':
				return 6;
			case 'My Programs':
				return 7;
			case 'Browse Courses':
				return 10;
			case 'Browse Programs':
				return 7;
			case 'Profile':
				return 11;
			case 'Payment History':
				return 12;
			case 'My Certificates':
				return 13;
			case 'Transcript':
				return 14;
			case 'Groups':
				return 15;
			case 'Codes':
				return 16;
			case 'Enrollment':
				return 2;
			case 'Students':
				return 0;
			case 'Login Info':
				return 17;
			case 'My Profile':
				return 11;
			case 'Billing':
				return 18;
			case 'Support':
				return 19;
			default:
				return 21;
		}
	}

	@Input() smallScreen?: boolean;

	@ViewChildren('childrenContainer') childrenContainers!: QueryList<ElementRef<HTMLDivElement>>;

	rootDropdownExpand = {} as { [key: string]: boolean };

	trackByFn: TrackByFunction<TreeNode<ABP.Route>> = (_, item) => item.name;

	allowedRoutes: TreeNode<ABP.Route>[] = [];

	private userAllowed = {
		Student: [
			'My Courses',
			'My Programs',
			'Browse Courses',
			'Browse Programs',
			'Profile',
			'Payment History',
			'My Certificates',
			'Transcript',
		],
		Partner: [
			'Groups',
			'Codes',
			'Enrollment',
			'Students',
			'Login Info',
			'My Profile',
			'Billing',
			'Support',
			'::Menu:Home',
		],
	};

	constructor(
		public readonly routesService: RoutesService,
		protected renderer: Renderer2,
		private _config: ConfigStateService
	) {}

	// Get name from child route to use as ID for collapsible container
	extractName(name: string) {
		const regex = /(\w+)$/g;
		return name.match(regex)[0];
	}

	ngOnInit() {
		let userRole: string;
		this._config.getOne$('currentUser').subscribe(currentUser => {
			if (currentUser.roles.includes('Student')) {
				userRole = 'Student';
			} else if (currentUser.roles.includes('Partner')) {
				userRole = 'Partner';
			} else if (currentUser.roles.includes('admin')) {
				userRole = 'admin';
			}

			this.routesService.visible$.subscribe(results => {
				results.forEach(route => {
					if (userRole === 'Student') {
						if (this.userAllowed.Student.includes(route.name)) {
							this.allowedRoutes.push(route);
						}
					} else if (userRole === 'Partner') {
						if (this.userAllowed.Partner.includes(route.name)) {
							this.allowedRoutes.push(route);
						}
					} else if (userRole === 'admin') {
						if (
							!this.userAllowed.Partner.includes(route.name) &&
							!this.userAllowed.Student.includes(route.name)
						) {
							this.allowedRoutes.push(route);
						}
					}
				});
			});
		});
	}

	// Keep track of collapsed menu items {string: boolean}
	collapsedValues = {};
}
