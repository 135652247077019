import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import "@angular/compiler";

//#region PrimeNG
import { DialogModule } from 'primeng/dialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { SidebarModule } from 'primeng/sidebar';
import { TimelineModule } from 'primeng/timeline';
import { BlockUIModule } from 'primeng/blockui';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipModule } from 'primeng/chip';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { EditorModule } from 'primeng/editor';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InplaceModule } from 'primeng/inplace';
import { BadgeModule } from 'primeng/badge';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { PanelModule } from 'primeng/panel';
import { InputMaskModule } from 'primeng/inputmask';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService, MessageService } from 'primeng/api';
import { VideoPlayerComponent } from './media/video-player.component';
import { FormValidationComponent } from './form-validation/form-validation.component';
import { SafePipe } from './pipe/safe.pipe';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { HttpErrorsComponent } from './http-errors/http-errors.component';
import { StepsModule } from 'primeng/steps';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabMenuModule } from 'primeng/tabmenu';
import { PadLeftPipe } from './pipe/pad-left.pipe';
import { RoundDownPipe } from './pipe/round-down.pipe';
import { DialogVideoPlayerComponent } from './media/dialog-video-player.component';
import { CKEditorModule } from 'ckeditor4-angular';

//#endregion

@NgModule({
	declarations: [
		VideoPlayerComponent,
		DialogVideoPlayerComponent,
		SafePipe,
		FormValidationComponent,
		HttpErrorsComponent,
		PadLeftPipe,
		RoundDownPipe,
	],
	imports: [
		CoreModule,
		ThemeSharedModule,
		CommercialUiModule,
		NgbDropdownModule,
		NgxValidateCoreModule,
		CKEditorModule,
		//#region PrimeNG
		TableModule,
		TabMenuModule,
		BreadcrumbModule,
		StepsModule,
		ToggleButtonModule,
		InputTextareaModule,
		ToolbarModule,
		ButtonModule,
		SplitButtonModule,
		DialogModule,
		ColorPickerModule,
		TabViewModule,
		AutoCompleteModule,
		CalendarModule,
		ChipsModule,
		SidebarModule,
		TimelineModule,
		BlockUIModule,
		CardModule,
		DataViewModule,
		ProgressSpinnerModule,
		ConfirmDialogModule,
		FileUploadModule,
		HttpClientModule,
		InputTextModule,
		DropdownModule,
		MultiSelectModule,
		ChipModule,
		AccordionModule,
		InputSwitchModule,
		CheckboxModule,
		ScrollPanelModule,
		EditorModule,
		RadioButtonModule,
		AvatarModule,
		AvatarGroupModule,
		InplaceModule,
		BadgeModule,
		PaginatorModule,
		ToastModule,
		TooltipModule,
		ContextMenuModule,
		PanelModule,
		InputMaskModule,
		ListboxModule,
		ConfirmPopupModule,
		//#endregion
	],
	exports: [
		CoreModule,
		ThemeSharedModule,
		CommercialUiModule,
		NgbDropdownModule,
		NgxValidateCoreModule,
		VideoPlayerComponent,
		FormValidationComponent,
		SafePipe,
		PadLeftPipe,
		RoundDownPipe,
		CKEditorModule,
		//#region PrimeNG
		TableModule,
		TabMenuModule,
		BreadcrumbModule,
		StepsModule,
		ToggleButtonModule,
		InputTextareaModule,
		ToolbarModule,
		ButtonModule,
		SplitButtonModule,
		DialogModule,
		ColorPickerModule,
		TabViewModule,
		AutoCompleteModule,
		CalendarModule,
		ChipsModule,
		SidebarModule,
		TimelineModule,
		BlockUIModule,
		CardModule,
		DataViewModule,
		ProgressSpinnerModule,
		ConfirmDialogModule,
		FileUploadModule,
		HttpClientModule,
		InputTextModule,
		DropdownModule,
		MultiSelectModule,
		ChipModule,
		AccordionModule,
		InputSwitchModule,
		CheckboxModule,
		ScrollPanelModule,
		EditorModule,
		RadioButtonModule,
		AvatarModule,
		AvatarGroupModule,
		InplaceModule,
		BadgeModule,
		PaginatorModule,
		ToastModule,
		TooltipModule,
		ContextMenuModule,
		PanelModule,
		InputMaskModule,
		ListboxModule,
		ConfirmPopupModule,
		HttpErrorsComponent,
		//#endregion
	],
	providers: [ConfirmationService, MessageService],
})
export class SharedModule {}
