import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/',
				name: '::Menu:Home',
				iconClass: 'fas fa-home',
				order: 1,
				layout: eLayoutType.application,
			},

			// {
			// 	path: '/reports',
			// 	name: 'Reports',
			// 	iconClass: 'fas fa-chart-line',
			// 	order: 4,
			// 	layout: eLayoutType.application,
			// },

			// {
			// 	path: '/my-courses',
			// 	name: 'My Courses',
			// 	iconClass: 'fas fa-chart-line',
			// 	order: 11,
			// 	layout: eLayoutType.application,
			// },

			// {
			// 	path: '/my-programs',
			// 	name: 'My Programs',
			// 	iconClass: 'fas fa-chart-line',
			// 	order: 12,
			// 	layout: eLayoutType.application,
			// },

			// {
			// 	path: '/browse-courses',
			// 	name: 'Browse Courses',
			// 	iconClass: 'fas fa-chart-line',
			// 	order: 13,
			// 	layout: eLayoutType.application,
			// },

			// {
			// 	path: 'profile',
			// 	name: 'Profile',
			// 	iconClass: 'fas fa-chart-line',
			// 	order: 14,
			// 	layout: eLayoutType.application,
			// },

			// {
			//   path: '/dashboard',
			//   name: '::Menu:Dashboard',
			//   iconClass: 'fas fa-chart-line',
			//   order: 2,
			//   layout: eLayoutType.application,
			//   requiredPolicy: 'PMC.Dashboard.Host  || PMC.Dashboard.Tenant',
			// },
		]);
	};
}
