import { Environment } from '@abp/ng.core';

const baseUrl = 'https://app.petramedicalcollege.com';

const oAuthConfig = {
	issuer: 'https://auth.petramedicalcollege.com/',
	redirectUri: baseUrl,
	clientId: 'PMC_App',
	responseType: 'code',
	scope: 'offline_access PMC',
	requireHttps: true,
};

export const environment = {
	production: true,
	application: {
		baseUrl,
		name: 'PMC',
	},
	sentryEnvName: 'Production',
	oAuthConfig,
	simticsUrl: 'https://lti.simtics.com/api/v1/new',
	apis: {
		default: {
			url: 'https://api.petramedicalcollege.com',
			rootNamespace: 'PMC',
		},
		AbpAccountPublic: {
			url: oAuthConfig.issuer,
			rootNamespace: 'AbpAccountPublic',
		},
		Web: {
			url: 'https://www.petramedicalcollege.com',
			rootNamespace: 'PMC'
		}
	},
	remoteEnv: {
		url: '/getEnvConfig',
		mergeStrategy: 'deepmerge',
	},
} as Environment;
