import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const BROWSE_COURSES_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/browse-courses',
				iconClass: 'fas fa-file-alt',
				name: 'Browse Courses',
				layout: eLayoutType.application,
				requiredPolicy: 'PMC.Courses',
				order: 13,
			},
		]);
	};
}
