import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const BILLING_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/billing',
				iconClass: 'fas fa-file-alt',
				name: 'Billing',
				layout: eLayoutType.application,
				requiredPolicy: 'PMC.Partner',
				order: 21,
				invisible: true,
			},
		]);
	};
}
