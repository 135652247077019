<nav id="navbar" class="navbar navbar-dark bg-primary navbar-expand-xl">
	<div class="container-fluid">
		<a role="button" routerLink="/" class="navbar-brand ms-4">
			<img src="./assets/logo.svg" alt="logo" />
		</a>

		<div class="navbar-nav">
			<ul
				*ngIf="isLoggedIn"
				class="d-none d-xl-flex mb-0 me-5 align-items-center"
				style="list-style: none">
				<li class="nav-item">
					<p-avatar *ngIf="profilePic && profileType === 2" shape="circle" size="large">
						<img [src]="profilePic" style="width: 50px; height: 50px;" />
					</p-avatar>
					<p-avatar *ngIf="profileType === 0" [label]="initials" shape="circle" size="large">
					</p-avatar>
				</li>

				<div ngbDropdown class="d-inline-block">
					<a
						ngbDropdownToggle
						role="button"
						class="nav-link remove-dropdown-icon"
						id="dropdownBasic1">
						<div class="username">
							{{ userName }}
						</div>
						<div class="userrole">
							{{ userRole }}
						</div>
					</a>

					<div
						ngbDropdownMenu
						class="dropdown-menu dropdown-menu-end"
						aria-labelledby="dropdownBasic1">
						<div *ngIf="userRole === 'Partner'">
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['/login-info']">
								<i class="isax isax-login"></i>
								Login Info
							</a>
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['/my-profile']">
								<i class="isax isax-user"></i>
								Profile
							</a>
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['/billing']">
								<i class="isax isax-receipt"></i>
								Billing
							</a>
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['/support']">
								<i class="isax isax-headphone"></i>
								Support
							</a>
						</div>
						<div *ngIf="userRole === 'Student'">
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['/profile']">
								<i class="isax isax-user"></i>
								Profile
							</a>
							<a
								ngbDropdownItem
								class="dropdown-item"
								[routerLink]="['/payment-history']">
								<i class="isax isax-dollar-square"></i>
								Payment History
							</a>
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['/support']">
								<i class="isax isax-headphone"></i>
								Support
							</a>
						</div>
						<div *ngIf="userRole === 'admin'">
							<a
								ngbDropdownItem
								class="dropdown-item"
								[routerLink]="['/account/security-logs']">
								<i class="isax isax-security"></i>
								Security Logs
							</a>
							<a ngbDropdownItem class="dropdown-item" [routerLink]="['gdpr']">
								<i class="isax isax-tag-user"></i>
								Personal Data
							</a>
						</div>
						<a ngbDropdownItem class="dropdown-item" (click)="logout()" role="button">
							<i class="isax isax-logout"></i>
							Sign Out
						</a>
					</div>
				</div>
			</ul>
		</div>

		<div class="d-xl-none d-flex" *ngIf="isLoggedIn">
			<div ngbDropdown class="mx-2 d-inline-block d-xl-none" >
				<a
					ngbDropdownToggle
					role="button"
					class="remove-dropdown-icon text-decoration-none">

						<p-avatar *ngIf="profilePic && profileType === 2" shape="circle" size="large">
							<img [src]="profilePic" />
						</p-avatar>
						<p-avatar *ngIf="profileType === 0" [label]="initials" shape="circle" size="large">
						</p-avatar>
				</a>
				<div
					ngbDropdownMenu
					class="dropdown-menu dropdown-menu-end"
					aria-labelledby="dropdownBasic1">
					<div *ngIf="userRole === 'Partner'">
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['']">Dashboard</a>
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['/login-info']">
							<i class="isax isax-login"></i>
							Login Info
						</a>
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['/my-profile']">
							<i class="isax isax-user"></i>
							Profile
						</a>
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['/billing']">
							<i class="isax isax-receipt"></i>
							Billing
						</a>
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['/support']">
							<i class="isax isax-headphone"></i>
							Support
						</a>
					</div>
					<div *ngIf="userRole === 'Student'">
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['/profile']">
							<i class="isax isax-user"></i>
							Profile
						</a>
						<a
							ngbDropdownItem
							class="dropdown-item"
							[routerLink]="['/payment-history']">
							<i class="isax isax-dollar-square"></i>
							Payment History
						</a>
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['/support']">
							<i class="isax isax-headphone"></i>
							Support
						</a>
					</div>
					<div *ngIf="userRole === 'admin'">
						<a
							ngbDropdownItem
							class="dropdown-item"
							[routerLink]="['/account/security-logs']">
							<i class="isax isax-security"></i>
							Security Logs
						</a>
						<a ngbDropdownItem class="dropdown-item" [routerLink]="['gdpr']">
							<i class="isax isax-tag-user"></i>
							Personal Data
						</a>
					</div>
					<a ngbDropdownItem class="dropdown-item" (click)="logout()" role="button">
						<i class="isax isax-logout"></i>
						Sign Out
					</a>
				</div>
			</div>
			<button class="navbar-toggler" type="button" (click)="open(content)">
				<span class="navbar-toggler-icon"></span>
			</button>
		</div>
	</div>
</nav>

<ng-template #content let-offcanvas>
	<div class="offcanvas-header mt-4 justify-content-around">
		<a role="button" routerLink="/" ngbAutofocus class="offcanvas-title ms-4 fw-bold fs-4 dashboard-title text-decoration-none">Dashboard</a>
		<button
			type="button"
			class="btn-close"
			aria-label="Close"
			(click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body pe-0">
		<div class="w-100">
			<abp-routes
				*abpReplaceableTemplate="{
					componentKey: service.routesComponentKey,
					inputs: {
						smallScreen: { value: service.smallScreen }
					}
				}"
				[smallScreen]="service.smallScreen"></abp-routes>
		</div>
	</div>
</ng-template>
