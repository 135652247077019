import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SUPPORT_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/support',
				iconClass: 'fas fa-file-alt',
				name: 'Support',
				layout: eLayoutType.application,
				requiredPolicy: 'PMC.Programs',
				order: 22,
				invisible: true,
			},
		]);
	};
}
