import { CoreModule } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule, ToasterService } from '@abp/ng.theme.shared';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
//import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
//import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { COURSES_COURSE_ROUTE_PROVIDER } from './courses/course/providers/course-route.provider';
import { STUDENTS_STUDENT_ROUTE_PROVIDER } from './students/student/providers/student-route.provider';
import { PARTNERS_PARTNER_ROUTE_PROVIDER } from './partners/partner/providers/partner-route.provider';
import { PROGRAMS_PROGRAM_ROUTE_PROVIDER } from './programs/program/providers/program-route.provider';
import { ENROLLMENTS_ENROLLMENT_ROUTE_PROVIDER } from './enrollments/enrollment/providers/enrollment-route.provider';
import { APPROVALS_APPROVAL_ROUTE_PROVIDER } from './approvals/approval/providers/approval-route.provider';
import { MEDIA_FILES_MEDIA_FILE_ROUTE_PROVIDER } from './media-files/media-file/providers/media-file-route.provider';
import { REPORTS_ROUTE_PROVIDER } from './reports/providers/reports-route.provider';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { PrimengToasterService } from './services/primeng-toaster.service.service';
import { SharedModule } from './shared/shared.module';
import { LEADS_LEAD_ROUTE_PROVIDER } from './leads/lead/providers/lead-route.provider';
import { MY_COURSES_ROUTE_PROVIDER } from './my-courses/providers/my-courses-route.provider';
import { MY_PROGRAMS_ROUTE_PROVIDER } from './my-programs/providers/my-programs-route.provider';
import { BROWSE_COURSES_ROUTE_PROVIDER } from './browse-courses/providers/browse-courses-route.provider';
import { BROWSE_PROGRAMS_ROUTE_PROVIDER } from './browse-programs/providers/browse-programs-route.provider';
import { PROFILE_ROUTE_PROVIDER } from './profile/providers/profile-routing.provider';
import { PAYMENT_HISTORY_ROUTE_PROVIDER } from './payment-history/providers/payment-history-route.provider';
import { MY_CERTIFICATES_ROUTE_PROVIDER } from './my-certificates/providers/my-certificates-route.provider';
import { TRANSCRIPT_ROUTE_PROVIDER } from './transcript/provider/transcript-route.provider';
import { AccordionModule } from 'primeng/accordion';
import { GROUPS_ROUTE_PROVIDER } from './groups/provider/groups-route.provider';
import { CODES_ROUTE_PROVIDER } from './codes/providers/codes-route.provider';
import { PARTNER_ENROLLMENT_ROUTE_PROVIDER } from './partner-enrollment/provider/partner-enrollment-route.provider';
import { PARTNER_STUDENTS_ROUTE_PROVIDER } from './partner-students/provider/partner-students-route.provider';
import { LOGIN_INFO_ROUTE_PROVIDER } from './login-info/provider/login-info-route.provider';
import { PARTNER_PROFILE_ROUTE_PROVIDER } from './partner-profile/provider/partner-profile-route.provider';
import { BILLING_ROUTE_PROVIDER } from './billing/provider/billing-route.provider';
import { SUPPORT_ROUTE_PROVIDER } from './support/provider/support-route.provider';
import { ErrorsManagementService } from './shared/http-errors/errors-management.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { CustomErrorHandler } from 'src/errorHandler';
import { BLOGS_ROUTE_PROVIDER } from './blogs/providers/blogs-route.provider';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AccordionModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		CoreModule.forRoot({
			environment,
			registerLocaleFn: registerLocale(),
		}),
		// ThemeSharedModule.forRoot({
		//   httpErrorConfig: {
		//     errorScreen: {
		//       component: HttpErrorComponent,
		//       forWhichErrors: [401, 403, 404, 500],
		//       hideCloseIcon: true,
		//     },
		//   },
		// }),
		AccountAdminConfigModule.forRoot(),
		AbpOAuthModule.forRoot(),
		AccountPublicConfigModule.forRoot(),
		IdentityConfigModule.forRoot(),
		LanguageManagementConfigModule.forRoot(),
		SaasConfigModule.forRoot(),
		AuditLoggingConfigModule.forRoot(),
		OpeniddictproConfigModule.forRoot(),
		TextTemplateManagementConfigModule.forRoot(),
		SettingManagementConfigModule.forRoot(),
		ThemeSharedModule.forRoot({
			validation: {
				blueprints: {
					maxlength: '::MaxLength[{{ requiredLength }}]',
				},
			},
		}),
		ThemeBasicModule.forRoot(),
		//SideMenuLayoutModule.forRoot(),
		CommercialUiConfigModule.forRoot(),
		GdprConfigModule.forRoot({
			cookieConsent: {
				privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
				cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
			},
		}),
	],
	providers: [
		APP_ROUTE_PROVIDER,
		COURSES_COURSE_ROUTE_PROVIDER,
		STUDENTS_STUDENT_ROUTE_PROVIDER,
		PARTNERS_PARTNER_ROUTE_PROVIDER,
		PROGRAMS_PROGRAM_ROUTE_PROVIDER,
		ENROLLMENTS_ENROLLMENT_ROUTE_PROVIDER,
		APPROVALS_APPROVAL_ROUTE_PROVIDER,
		MEDIA_FILES_MEDIA_FILE_ROUTE_PROVIDER,
		MY_COURSES_ROUTE_PROVIDER,
		REPORTS_ROUTE_PROVIDER,
		MY_PROGRAMS_ROUTE_PROVIDER,
		BROWSE_COURSES_ROUTE_PROVIDER,
		BROWSE_PROGRAMS_ROUTE_PROVIDER,
		PROFILE_ROUTE_PROVIDER,
		PAYMENT_HISTORY_ROUTE_PROVIDER,
		MY_CERTIFICATES_ROUTE_PROVIDER,
		TRANSCRIPT_ROUTE_PROVIDER,
		GROUPS_ROUTE_PROVIDER,
		CODES_ROUTE_PROVIDER,
		PARTNER_ENROLLMENT_ROUTE_PROVIDER,
		PARTNER_STUDENTS_ROUTE_PROVIDER,
		LOGIN_INFO_ROUTE_PROVIDER,
		PARTNER_PROFILE_ROUTE_PROVIDER,
		BILLING_ROUTE_PROVIDER,
		SUPPORT_ROUTE_PROVIDER,
		BLOGS_ROUTE_PROVIDER,
		{ provide: AppComponent, useClass: AppComponent },
		{ provide: ToasterService, useClass: PrimengToasterService },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorsManagementService, multi: true },
		{
			provide: ErrorHandler,
			useValue: new CustomErrorHandler({
				showDialog: false,
				logErrors: true,
			}),
		},
		LEADS_LEAD_ROUTE_PROVIDER,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
