import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		canActivate: [AuthGuard],
		data: { roles: ['any'] },
	},
	{
		path: 'redirect',
		pathMatch: 'full',
		loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule),
		data: { roles: ['any'] },
	},
	{
		path: 'account',
		loadChildren: () =>
			import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'gdpr',
		loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'identity',
		loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'language-management',
		loadChildren: () =>
			import('@volo/abp.ng.language-management').then(m =>
				m.LanguageManagementModule.forLazy()
			),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'saas',
		loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'audit-logs',
		loadChildren: () =>
			import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'openiddict',
		loadChildren: () =>
			import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'text-template-management',
		loadChildren: () =>
			import('@volo/abp.ng.text-template-management').then(m =>
				m.TextTemplateManagementModule.forLazy()
			),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'setting-management',
		loadChildren: () =>
			import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'gdpr-cookie-consent',
		loadChildren: () =>
			import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
				m => m.GdprCookieConsentModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'courses',
		loadChildren: () => import('./courses/course/course.module').then(m => m.CourseModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'students',
		loadChildren: () => import('./students/student/student.module').then(m => m.StudentModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'partners',
		loadChildren: () => import('./partners/partner/partner.module').then(m => m.PartnerModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'programs',
		loadChildren: () => import('./programs/program/program.module').then(m => m.ProgramModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'enrollments',
		loadChildren: () =>
			import('./enrollments/enrollment/enrollment.module').then(m => m.EnrollmentModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'approvals',
		loadChildren: () =>
			import('./approvals/approval/approval.module').then(m => m.ApprovalModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'media-files',
		loadChildren: () =>
			import('./media-files/media-file/media-file.module').then(m => m.MediaFileModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},

	{
		path: 'reports',
		loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'leads',
		loadChildren: () => import('./leads/lead/lead.module').then(m => m.LeadModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: 'my-courses',
		loadChildren: () => import('./my-courses/my-courses.module').then(m => m.MyCoursesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'my-programs',
		loadChildren: () =>
			import('./my-programs/my-programs.module').then(m => m.MyProgramsModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'browse-courses',
		loadChildren: () =>
			import('./browse-courses/browse-courses.module').then(m => m.BrowseCoursesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'browse-programs',
		loadChildren: () =>
			import('./browse-programs/browse-programs.module').then(m => m.BrowseProgramsModule),
	},
	{
		path: 'support',
		loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student', 'Partner'] },
	},
	{
		path: 'profile',
		loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'payment-history',
		loadChildren: () =>
			import('./payment-history/payment-history.module').then(m => m.PaymentHistoryModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'my-certificates',
		loadChildren: () =>
			import('./my-certificates/my-certificates.module').then(m => m.MyCertificatesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'transcript',
		loadChildren: () => import('./transcript/transcript.module').then(m => m.TranscriptModule),
		canActivate: [AuthGuard],
		data: { roles: ['Student'] },
	},
	{
		path: 'groups',
		loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'codes',
		loadChildren: () => import('./codes/codes.module').then(m => m.CodesModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'enrollment',
		loadChildren: () =>
			import('./partner-enrollment/partner-enrollment.module').then(
				m => m.PartnerEnrollmentModule
			),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'all-students',
		loadChildren: () =>
			import('./partner-students/partner-students.module').then(m => m.PartnerStudentsModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'login-info',
		loadChildren: () => import('./login-info/login-info.module').then(m => m.LoginInfoModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'my-profile',
		loadChildren: () =>
			import('./partner-profile/partner-profile.module').then(m => m.PartnerProfileModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'billing',
		loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
		canActivate: [AuthGuard],
		data: { roles: ['Partner'] },
	},
	{
		path: 'blogs',
		loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule),
		canActivate: [AuthGuard],
		data: { roles: ['admin'] },
	},
	{
		path: '**',
		redirectTo: '/',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
