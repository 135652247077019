import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LEADS_LEAD_ROUTE_PROVIDER = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
    return () => {
        routes.add([
            {
                path: '/leads',
                iconClass: 'fas fa-file-alt',
                name: '::Menu:Leads',
                layout: eLayoutType.application,
                requiredPolicy: 'PMC.Leads',
                order: 7,
            },
        ]);
    };
}
