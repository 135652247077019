import { Component } from '@angular/core';
import { ReplaceableComponentsService, ConfigStateService } from '@abp/ng.core'; // imported ReplaceableComponentsService
import { eThemeBasicComponents, LogoComponent, NavItemsComponent } from '@abp/ng.theme.basic'; // imported eThemeBasicComponents
import { Router } from '@angular/router';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { SettingsTabComponent } from './settings-tab/media-file-tab/settings-tab.component';
import { SupportVideosTabComponent } from './settings-tab/support-videos-tab/support-videos-tab.component';
import { MarketingTabComponent } from './settings-tab/marketing-tab/marketing-tab.component';
import * as Sentry from "@sentry/angular-ivy";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor(
		private replaceableComponents: ReplaceableComponentsService,
		private router: Router,
		private _settingTabs: SettingTabsService,
		private configStateService: ConfigStateService
	) {} // injected ReplaceableComponentsService

	ngOnInit() {
		this.configStateService.getOne$('currentUser').subscribe(user => {
			if (user.isAuthenticated)
			{
				Sentry.setContext("userInfo", user);
			}
		});

		this._settingTabs.add([
			{
				name: 'Media',
				component: SettingsTabComponent,
			},
			{
				name: 'Support Videos',
				component: SupportVideosTabComponent
			},
			{
				name: 'Marketing',
				component: MarketingTabComponent
			}
		]);

		this.replaceableComponents.add({
			component: LogoComponent,
			key: eThemeBasicComponents.Logo,
		});

		this.replaceableComponents.add({
			component: NavItemsComponent,
			key: eThemeBasicComponents.NavItems,
		});
	}
}
