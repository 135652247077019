import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const roles = route.data.roles as Array<string>;
		return this.authService.getCurrentUser().pipe(
			map(currentUser => {
				if (!currentUser.isAuthenticated) {
					if (roles.includes('any')) {
						return true;
					}
					this.authService.redirectUrl = state.url;
					this.router.navigate(['/']);
					return false;
				} else {
					if (roles.includes('any')) {
						if (this.authService.redirectUrl) {
							this.router.navigate([this.authService.redirectUrl]);
						}
						return true;
					}
					if (roles.includes(currentUser.roles[0])) {
						this.authService.clearRedirectUrl();
						return true;
					}
					this.authService.clearRedirectUrl();
					this.router.navigate(['/']);
					return false;
				}
			})
		);
	}
}
