<div *ngFor="let item of settingsNames" class="mt-3 mb-4">
    <div class="w-100 d-flex align-items-center justify-content-between mb-3">
        <h5>
            {{ "::" + item | abpLocalization }}
        </h5>
        <button pButton [loading]="isLoading[item]" class="btn btn-secondary" (click)="saveMedia(item)" label="Save Change"></button>
    </div>
    <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="existingFiles[item] && !existingFiles[item].deleted">
        <a target="_blank" rel="noopener noreferrer" [href]="existingFiles[item].downloadLink">
            {{ existingFiles[item].fileName }}
        </a>
        <button
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            class="p-button-rounded p-button-text p-button-danger"
            title="Delete"
            (click)="deleteItem(item)"
        ></button>
    </div>
    <div [hidden]="existingFiles[item] && !existingFiles[item].deleted">
        <p-fileUpload
            name="{{ item }}"
            #mediaInput
            accept=".pdf"
            customUpload="true"
            [showUploadButton]="false"
            [showCancelButton]="false"
            chooseLabel="Browse"
            maxFileSize="10000000000"
            fileLimit="1"
            files="">
            <ng-template let-files pTemplate="content">
            <p *ngIf="files.length === 0" class="text-muted">
                Drag your PDF here, or browse for one.
            </p>
            </ng-template>
        </p-fileUpload>
    </div>
    
</div>