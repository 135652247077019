import { mapEnumToOptions } from '@abp/ng.core';

export enum MediaFileType {
  Image = 'Image',
  Video = 'Video',
  Video3D = 'Video3D',
  Audio = 'Audio',
  File = 'File',
}

export const mediaFileTypeOptions = mapEnumToOptions(MediaFileType);
