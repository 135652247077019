import { ConfigStateService, LocalizationService, CoreModule } from '@abp/ng.core';
import { Toaster, ToasterService } from '@abp/ng.theme.shared';
import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MediaFileModelDto, MediaFileType, MediaFileUploadSourceType } from '@proxy/media-files';
import { MediaSettingsService } from '@proxy/media-settings';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { SettingsFilesService } from '../settings-files.service';
import { takeLast } from 'rxjs';

interface MediaFileModelDtoDeleteFlag extends MediaFileModelDto {
    deleted?: boolean;
}

@Component({
    standalone: true,
    selector: 'app-support-videos-tab',
    templateUrl: 'support-videos-tab.component.html',
    imports: [ 
        CoreModule,
        FileUploadModule
    ]
})

export class SupportVideosTabComponent implements OnInit, AfterViewInit {
    @ViewChildren('videoInput') videoInput: QueryList<FileUpload>;
    @ViewChildren('thumbnailInput') thumbnailInput: QueryList<FileUpload>;

    public linkedNames: [string, string][];
    public isLoading: { [key: string] : boolean } = {};
    public uploadedFiles: { [key: string] : MediaFileModelDtoDeleteFlag } = {};
    public existingFiles: { [key: string] : MediaFileModelDtoDeleteFlag } = {};
    private options: Partial<Toaster.ToastOptions> = { sticky: true, tapToDismiss: true };
    private userId: string;

    constructor(
        private _config: ConfigStateService,
        private _mediaSettingsService: MediaSettingsService,
        private _settingsFileService: SettingsFilesService,
        private _localizationService: LocalizationService,
        private _toasterService: ToasterService
    ) { 
        const settings = this._config.getSettings("PMC.Support.Video");
        const settingsNames = Object.keys(settings);

        const thumbnails = this._config.getSettings("PMC.Support.Thumbnail");
        const thumbnailNames = Object.keys(thumbnails);

        this.linkedNames = settingsNames.map((value, index) => [value, thumbnailNames[index]]);
    }

    ngOnInit() { 
        this._config.getOne$('currentUser').subscribe(user => this.userId = user['id']);
    }

    ngAfterViewInit() {
        this.videoInput.forEach(input => {
            this.loadExisting(input.name);

            input.onSelect.subscribe(async e => {
                const file = e.currentFiles[0];
                this.uploadedFiles[input.name] = {
                    file: file,
                    fileType: MediaFileType.Video,
                    fileName: file.name
                };
            });

            input.onRemove.subscribe(async () => {
                this.uploadedFiles[input.name] = undefined;
            });
        });

        this.thumbnailInput.forEach(input => {
            this.loadExisting(input.name);

            input.onSelect.subscribe(async e => {
                const file = e.currentFiles[0];
                this.uploadedFiles[input.name] = {
                    file: file,
                    fileType: MediaFileType.Image,
                    fileName: file.name
                };
            });

            input.onRemove.subscribe(async () => {
                this.uploadedFiles[input.name] = undefined;
            });
        });
    }

    private updateMedia(formData: FormData) {
        this._settingsFileService.updateMedia(formData).pipe(takeLast(1)).subscribe(result => {
            this._toasterService.success(`Successfully updated ${this._localizationService.instant("::" + formData.get('mediaSetting'))}!`, null, this.options);
            this.isLoading[formData.get('mediaSetting').toString()] = false;
        });
    }

    public saveMedia(settingNames: [string, string]) {
        this.isLoading[settingNames[0]] = true;
        this.isLoading[settingNames[1]] = true;
        settingNames.forEach(settingName => {
            const localizedName = this._localizationService.instant("::" + settingName);
            const formData = new FormData();
    
            formData.append("sourceUserId", this.userId);
            formData.append("mediaSetting", settingName);
            formData.append("mediaUploadSource", MediaFileUploadSourceType.SupportVideos);
    
            if (this.uploadedFiles[settingName]) {
                formData.append("file.fileType", this.uploadedFiles[settingName].fileType);
                formData.append("file.name", localizedName);
                formData.append("file.file", this.uploadedFiles[settingName].file);
                formData.append("existingFileId", this.existingFiles[settingName]?.id ?? "");
            }
            else if (this.existingFiles[settingName] && this.existingFiles[settingName].deleted) {
                formData.append("existingFileId", "");
            }
            else {
                return;
            }
            this.updateMedia(formData);
        });
    }

    public loadExisting(settingName: string) {
        this._mediaSettingsService.getMedia(settingName).subscribe(result => {
            this.existingFiles[settingName] = result;
            if (this.existingFiles[settingName]) {
                this.existingFiles[settingName].deleted = false;
            }
        });
    }

    public deleteItem(settingName: string) {
        this.existingFiles[settingName].deleted = true;
    }
}