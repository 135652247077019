import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PARTNERS_PARTNER_ROUTE_PROVIDER = [
    { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
    return () => {
        routes.add([
            {
                path: '/partners',
                iconClass: 'fas fa-file-alt',
                name: '::Menu:Partners',
                layout: eLayoutType.application,
                requiredPolicy: 'PMC.Partners',
                order: 3,
            },
        ]);
    };
}
